export type Theme = "dark" | "light";

export type HomePage = {
    name: string,
    surname: string,
    title: string,
    mail: string,
    slogan: string,
    mainPicture: Picture,
    navigationElements: NavigationElement[],
    chapters: Chapter[];
}

export type NavigationElement = {
    header: string,
    destinations: string[],
}

export type Chapter = (TextChapter | ProjectChapter | EducationChapter | SkillsChapter | ContactChapter | DownloadChapter | OfferChapter);

export type TextChapter = {
    title: string;
    subchapters?: TextChapter[];
    texts: Content[];
};

export type OfferChapter = {
    title: string;
    offers: Offer[];
}

export type ProjectChapter = {
    type: "PROJECTS"
    title: string;
    projects: Project[];
}
export type EducationChapter = {
    title: string;
    educationStages: EducationStage[];
}
export type SkillsChapter = {
    type: "SKILLS"
    title: string;
    text: string;
    skills: Skill[];
    projects: Project[];
}
export type ContactChapter = {
    title: string;
    text: string;
    mail: string;
    description: string;
    linkedIn: string;
}
export type DownloadChapter = {
    title: string;
    downloadsDescription: string;
    buttonLabels: DownloadButtonLabel[];
    projectsForDownload: Project[],
    educationForDownload: EducationStage[],
    skillsForDownload: Skill[],
    cvAboutMe: string[],
}

export type DownloadButtonLabel = {
    id: DownloadButtonId;
    label: string;
}

export type DownloadButtonId = "CV" | "PROJECT_OVERVIEW";


export type Content = TextContent | Picture;

export type TextContent = {
    id: string;
    text: string;
    textType: "h1" | "h2" | "h3" | "p";
}

export type Picture = {
    id: string;
    url: string;
    text?: string;
}

export type Offer = {
    header: string,
    text: string,
}

export type Project = {
    start: Date;
    end: Date;
    type: "Employed" | "self-employed";
    title: string;
    text: string[];
    skills: Skill[];
    responsibilities: string[];
    field: string;
}
export type EducationStage = {
    start: Date;
    end: Date;
    degree: string;
    institution: string;
    name?: string;
    grade?: number;
}

export type MenuItem = {
    title: string;
    subchapters: string[];
}

export function determineIfChapterIsTextChapter(toBeDetermined: Chapter): toBeDetermined is TextChapter {
    return !!(toBeDetermined as TextChapter).texts;
}

export function determineIfChapterIsOfferChapter(toBeDetermined: Chapter): toBeDetermined is OfferChapter {
    return !!(toBeDetermined as OfferChapter).offers;
}

export function determineIfChapterIsProjectsChapter(toBeDetermined: Chapter): toBeDetermined is ProjectChapter {
    return (toBeDetermined as ProjectChapter).type === "PROJECTS";
}
export function determineIfChapterIsEducationChapter(toBeDetermined: Chapter): toBeDetermined is EducationChapter {
    return !!(toBeDetermined as EducationChapter).educationStages;
}

export function determineIfChapterIsSkillsChapter(toBeDetermined: Chapter): toBeDetermined is SkillsChapter {
    return (toBeDetermined as SkillsChapter).type === "SKILLS";
}

export function determineIfChapterIsDownloadChapter(toBeDetermined: Chapter): toBeDetermined is DownloadChapter {
    return !!(toBeDetermined as DownloadChapter).downloadsDescription;
}

export function determineIfChapterIsContact(toBeDetermined: Chapter): toBeDetermined is ContactChapter {
    return !!(toBeDetermined as ContactChapter).mail;
}

export function determineIfContentIsPicture(toBeDetermined: Content): toBeDetermined is Picture {
    return !!(toBeDetermined as Picture).url;
}
export function determineIfContentIsText(toBeDetermined: Content): toBeDetermined is TextContent {
    return !!(toBeDetermined as TextContent).textType;
}

export type SkillType="FRONTEND" | "BACKEND" | "DEVOPS" | "DATABASE"

export type Skill = {
    name: string;
    logoUrl?: string;
    rating: number;
    children: Skill[];
    skillType: SkillType;
}