import React, {FunctionComponent} from "react";
import "./Chapter.css"
import {
    Chapter,
    determineIfChapterIsProjectsChapter,
    determineIfChapterIsSkillsChapter,
    determineIfChapterIsTextChapter,
    determineIfChapterIsContact,
    determineIfChapterIsDownloadChapter,
    determineIfChapterIsOfferChapter, determineIfChapterIsEducationChapter
} from "../../../../model/types";
import {ProjectFragment} from "./project/ProjectFragment";
import {TextFragment} from "./textChapter/TextFragment";
import {SkillsFragment} from "./skill/SkillsFragment";
import {ContactFragment} from "./contact/ContactFragment";
import {DownloadFragment} from "./downloadChapter/DownloadFragment";
import {OfferFragment} from "./offer/OfferFragment";
import {EducationFragment} from "./education/EducationFragment";

export interface ChapterFragmentProps {
    chapter: Chapter;
    isSubchapter?: boolean;
}

export const ChapterFragment: FunctionComponent<ChapterFragmentProps> = (props) => {
    const {chapter, isSubchapter} = props;

    let className = "flex-column width-fluid";
    className += isSubchapter ? " subchapter" : " chapter";



    const mapChapter = (chapter: Chapter) => {
        if(determineIfChapterIsTextChapter(chapter)){
            return <TextFragment textChapter={chapter}/>
        } else if (determineIfChapterIsProjectsChapter(chapter)){
            return <ProjectFragment projectChapter={chapter}/>
        } else if (determineIfChapterIsEducationChapter(chapter)){
            return <EducationFragment educationChapter={chapter}/>
        } else if (determineIfChapterIsSkillsChapter(chapter)){
            return <SkillsFragment skillsChapter={chapter}/>
        }else if (determineIfChapterIsContact(chapter)){
            return <ContactFragment contactChapter={chapter}/>
        } else if (determineIfChapterIsDownloadChapter(chapter)){
            return <DownloadFragment downloadChapter={chapter}/>
        }else if (determineIfChapterIsOfferChapter(chapter)){
            return <OfferFragment offerChapter={chapter}/>
        }
    }

    return (
        <div key={chapter.title} id={chapter.title} className={className}>
            <h1 key={chapter.title} className="title">{chapter.title}</h1>
            {mapChapter(chapter)}
        </div>
    )
}