import React from 'react';
import {Page, Text, View, Document, StyleSheet} from '@react-pdf/renderer';
import {Project, Skill} from "../../../../../../model/types";
import {DateUtil} from "../../../../../../util/DateUtil";
import _ from "lodash";

const PRIAMRY_COLOR = "#560319";
// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        alignItems: 'center',
        padding:20,
    },
    title: {
        textAlign: 'center',
        fontSize: 24,
        color: PRIAMRY_COLOR,
    },
    name: {
        textAlign: 'center',
        fontSize: 20,
    },
    projectCard: {
        borderLeft: 1,
        borderLeftColor: '#898c8c',
        marginBottom: 15,
        padding: 5,
        width: '80%',
    },
    row: {
        flexDirection: "row",
        marginTop: 3,
    },
    rowHeader: {
        width: 100,
        color: PRIAMRY_COLOR,
        fontSize: 12,
    },
    rowText: {
        wrap: true,
        fontSize: 12,
        width: '80%'
    },
    skillRow: {
        flexDirection: "row",
        width: '80%',
        marginTop: 3,
    },
    skillCol: {
        flexDirection: 'column',
        width: '25%',
    },
    skillCollHeader: {
        fontSize: 12,
        color: PRIAMRY_COLOR,
    }

});

export interface ProjectOverviewPdfProps {
    projects: Project[]
}

const getRow = (header: string, content: string) => {
    return (
        <View style={styles.row}>
            <Text style={styles.rowHeader}>{header}:</Text>
            <Text style={styles.rowText}>{content}</Text>
        </View>
    )
}

const mapSkills = (skills: Skill[] | undefined, title: string) => {
    return skills && (
        <View style={styles.skillCol}>
            <Text style={styles.skillCollHeader}>{title}</Text>
            {skills.map((it, index) => {
                return (<Text style={styles.rowText} key={index}>{it.name}</Text>)
            })}
        </View>
    )
}

const renderSkillRow = (skills: Skill[]) => {
    const mappedSkills = _.groupBy(skills, 'skillType');
    return (
        <View style={styles.row}>
            <Text style={styles.rowHeader}>Skills:</Text>
            <View style={styles.skillRow}>
                {mapSkills(mappedSkills["FRONTEND"], "Frontend:")}
                {mapSkills(mappedSkills["BACKEND"], "Backend:")}
                {mapSkills(mappedSkills["DEVOPS"], "DevOps:")}
                {mapSkills(mappedSkills["DATABASE"], "Database:")}
            </View>
        </View>
    );
}

// Create Document Component
export const ProjectOverviewPdf = (props: ProjectOverviewPdfProps) => {
    const {projects} = props;
    const chunkedProjects: Project[][] = [];
    let i, j, chunkIndex, chunk = 4;
    for (i = 0, j = projects.length, chunkIndex = 0; i < j; i += chunk, chunkIndex++) {
        chunkedProjects[chunkIndex] = projects.slice(i, i + chunk);
        // do whatever
    }
    return (
        <Document>
            {chunkedProjects.map((projectsChunk, index) => {
                return (
                    <Page size="A4" style={styles.page}>
                        { index === 0 && <Text style={styles.title}>Projektübersicht</Text>}
                        { index === 0 && <Text style={styles.name}>Florian Englberger</Text>}
                            {projectsChunk.map(project => {
                                return (
                                    <View key={project.title} style={styles.projectCard}>
                                        {getRow("Start", DateUtil.formatDate(project.start))}
                                        {getRow("Ende", DateUtil.formatDate(project.end))}
                                        {getRow("Aufgaben", project.responsibilities.join(", "))}
                                        {getRow("Beschreibung", project.text.join("\n"))}
                                        {renderSkillRow(project.skills)}
                                        {getRow("Feld", project.field)}
                                    </View>)
                            })}
                    </Page>)
            })}
        </Document>
    )
};