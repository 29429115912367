import {FunctionComponent, useContext, useEffect, useState} from "react";
import {Link} from 'react-scroll';
import './Menu.css'
import {HomePageContext} from "../context/HomePageContext";

type MenuObjectProps = {
    label: string,
    subItems: string[];
    isChild: boolean;
    isFirst: boolean;
    hidden?: boolean;
}

export const MenuObject: FunctionComponent<MenuObjectProps> = ({
                                                                   label,
                                                                   subItems,
                                                                   isChild,
                                                                   isFirst,
                                                                   hidden,
                                                               }) => {

    const [isActive, setIsActive] = useState<boolean>(false);

    const {setMenuVisibility} = useContext(HomePageContext);

    const debouncedIsActive = useDebounce<boolean>(isActive, 300);

    let className = 'flex flex-center border remove-border-radius menu-object';
    if (isChild) {
        className += ' border-left padding-left-small'
    }
    if (hidden) {
        className += ' hidden'
    }

    const onSetActive = () => {
        setIsActive(true);
    }

    const onSetInactive = () => {
        setIsActive(false);
    }

    return (
        <div>
            <Link
                className={className}
                activeClass={'active'}
                to={label}
                onSetActive={onSetActive}
                onSetInactive={onSetInactive}
                spy={true}
                smooth={true}
                duration={500}
                containerId={"mainContent"}
                offset={isFirst ? -1000 : undefined}
                onClick={()=>setMenuVisibility(false)}
            >
                {label}
            </Link>
            {subItems.map(child =>
                <MenuObject
                    key={child}
                    label={child}
                    subItems={[]}
                    isChild={true}
                    hidden={!debouncedIsActive}
                    isFirst={false}
                />)}
        </div>

    )
}

function useDebounce<T>(value: T, delay: number) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Set debouncedValue to value (passed in) after the specified delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Return a cleanup function that will be called every time ...
            // ... useEffect is re-called. useEffect will only be re-called ...
            // ... if value changes (see the inputs array below).
            // This is how we prevent debouncedValue from changing if value is ...
            // ... changed within the delay period. Timeout gets cleared and restarted.
            // To put it in context, if the user is typing within our app's ...
            // ... search box, we don't want the debouncedValue to update until ...
            // ... they've stopped typing for more than 500ms.
            return () => {
                clearTimeout(handler);
            };
        },
        // Only re-call effect if value changes
        // You could also add the "delay" var to inputs array if you ...
        // ... need to be able to change that dynamically.
        [value, delay]
    );

    return debouncedValue;
}