import {ContactChapter} from "../../../../../model/types";
import {FunctionComponent} from "react";
import {Mailto} from "../../../mailto/Mailto";

export interface ContactFragmentProps {
    contactChapter: ContactChapter
}

export const ContactFragment: FunctionComponent<ContactFragmentProps> = ({contactChapter}) => {
    return(
        <div className="flex-column chapter-content margin-bottom-l">
            <p>{contactChapter.text}</p>
            <p className={"padding-top-l"}>{contactChapter.description}</p>
            <div className="padding-top-l">
                <Mailto className="mail" email={contactChapter.mail}>{contactChapter.mail}</Mailto>
            </div>
            <div className="padding-top-s">
                <a className="mail" href={contactChapter.linkedIn} target="_blank" rel="noreferrer">LinkedIn</a>
            </div>
        </div>
    )
}