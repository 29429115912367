import React from 'react';
import './App.css';
import {MainPage} from "./main/MainPage";
import {ThemeProvider} from "./style/ThemeProvider";
import {HomePageContext, useHomePageContext} from "./context/HomePageContext";

function App() {

    return (
        <HomePageContext.Provider value={useHomePageContext()}>
            <ThemeProvider>
                <MainPage/>
            </ThemeProvider>
        </HomePageContext.Provider>
    );
}

export default App;
