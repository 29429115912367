import React, {FunctionComponent, useContext} from "react";
import {DarkModeToggle} from "react-dark-mode-toggle-2";
import "./themeToggle.css"
import {HomePageContext} from "../../../context/HomePageContext";

export interface ThemeToggleProps {

}

export const ThemeToggle: FunctionComponent<ThemeToggleProps> = () => {
    const {theme, toggleTheme, setMenuVisibility} = useContext(HomePageContext)

    const onChange = () => {
        toggleTheme();
        setMenuVisibility(false);
    }

    return (
            <DarkModeToggle onChange={onChange} isDarkMode={theme === "dark"} size={80} className={"margin-bottom-l"}/>
    )
}