import React, {FunctionComponent, useContext} from "react";
import './sideMenu.css'
import {HomePageContext} from "../../context/HomePageContext";
import {MenuItem} from "../../model/types";
import {HomeButton} from "../../mainContent/fragments/atoms/buttons/homeButton/HomeButton";
import {MenuObject} from "../MenuObject";
import {ThemeToggle} from "../../mainContent/fragments/themeToggle/ThemeToggle";


type MenuProps = {
    menuItems: MenuItem[]
}

export const SideMenu: FunctionComponent<MenuProps> = ({menuItems}) => {

    const {isMenuOpen} = useContext(HomePageContext);

    let className="SideMenu flex-column";
    if(isMenuOpen) {
        className += " fullscreen";
    } else {
        className += " hidden";
    }

    return (
        <div className={className}>
            <HomeButton/>
            <div className='menu_object_container flex-column flex-center justify-self-center'>
                {menuItems.map((item, index) =>
                    <MenuObject
                        key={item.title}
                        label={item.title}
                        subItems={item.subchapters}
                        isChild={false}
                        isFirst={index === 0}
                    />
                )}
            </div>
            <div className="flex justify-content-center">
                <ThemeToggle/>
            </div>
        </div>
    )
}
