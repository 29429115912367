import {FunctionComponent} from "react";
import {DownloadButtonId, DownloadChapter} from "../../../../../model/types";
import {PDFDownloadLink, PDFViewer} from '@react-pdf/renderer';
import "./downloadChapter.css"
import {ProjectOverviewPdf} from "./projectOverviewPdf/ProjectOverviewPdf";
import {CvPdf} from "./cvPdf/CvPdf";

export interface DownloadFragmentProps {
    downloadChapter: DownloadChapter
}

export const DownloadFragment: FunctionComponent<DownloadFragmentProps> = ({downloadChapter}) => {

    const getDocument = (id: DownloadButtonId) => {
        if (id === "CV") {
            return <CvPdf projects={downloadChapter.projectsForDownload} education={downloadChapter.educationForDownload} skills={downloadChapter.skillsForDownload}
                          text={downloadChapter.cvAboutMe} imageSrc={'./main_picture.jpg'}/>
        } else if (id === "PROJECT_OVERVIEW") {
            return <ProjectOverviewPdf projects={downloadChapter.projectsForDownload}/>
        } else {
            return <></>;
        }
    }

    return (
        <div className="downloadChapter flex">
            {downloadChapter.buttonLabels.map(label =>
                <>
                    <PDFDownloadLink
                        key={label.id}
                        document={getDocument(label.id)}
                        fileName={label.label}
                        className={"PdfDownloadLink"}

                    >
                        {({blob, url, loading, error}) =>
                            loading ? 'Loading document...' : label.label
                        }
                    </PDFDownloadLink>
                    {/*{label.id === "CV" &&*/}
                    {/*<PDFViewer className={"w100"} style={{height: 800}}>{getDocument(label.id)}</PDFViewer>*/}
                    {/*}*/}
                </>
            )}
        </div>
    )
}