import React, {FunctionComponent, useContext} from "react";
import {HomePageContext} from "../context/HomePageContext";

export enum ElementSize {
    "large", "medium", "small", "tiny"
}

export enum ElementVariant {
    "solid", "outlined", "light"
}

interface ThemeProps {
    children: React.ReactNode,
}

export const ThemeProvider: FunctionComponent<ThemeProps> = (props) => {
    const {children} = props;

    const {theme} = useContext(HomePageContext);

    return (
        <div className={theme}>
            {children}
        </div>
    );
};