import {FunctionComponent} from "react";
import {
    Content,
    determineIfContentIsPicture,
    determineIfContentIsText,
    TextChapter,
    TextContent
} from "../../../../../model/types";
import {ChapterFragment} from "../ChapterFragment";
import "./text.css";

export interface TextFragmentProps{
    textChapter: TextChapter
    isSubChapter?: boolean;
}

export const TextFragment:FunctionComponent<TextFragmentProps> = (props) => {
    const {textChapter} = props;

    const mapContentToTsx = (contentElement: Content) => {
        if (determineIfContentIsPicture(contentElement)) {
            return (
                <div key={contentElement.id}>{contentElement.text}</div>
            );
        } else if (determineIfContentIsText(contentElement)) {
            switch ((contentElement as TextContent).textType) {
                case "h1":
                    return (
                        <h1 key={contentElement.id} id={contentElement.id} className={"padding-top-l width-fluid flex"}>
                            <h1 className={"padding--right-s"}>{contentElement.text}</h1>
                        </h1>);
                case "h2":
                    return (<h2 key={contentElement.id} id={contentElement.id}
                                className={"padding-top-m width-fluid padding-left-m"}>{`${contentElement.text}`}</h2>);
                case "h3":
                    return (<h3 key={contentElement.id} id={contentElement.id} className={"padding-top-s"}>{contentElement.text}</h3>);
                case "p":
                    return (<p key={contentElement.id} id={contentElement.id}>
                        {contentElement.text}
                    </p>);
            }
        }
    }

    return (
        <div key={textChapter.title} className="chapter-content">
            {textChapter.texts.map(mapContentToTsx)}
            {(textChapter.subchapters || []).map(subchapter => <ChapterFragment chapter={subchapter} isSubchapter={true}/>)}
        </div>
    )
}