import {Skill} from "../../../../../model/types";
import React, {FunctionComponent} from "react";
import "./skills.css";

export type SkillIconProps = {
    skill: Skill
}

export const SkillIcon:FunctionComponent<SkillIconProps> = (props => {
    const {skill} = props;

    return (
        <div>
            <img className="project-skill-logo margin-right-s border-radius" key={skill.name} src={skill.logoUrl}
                 alt="Logo"/>
        </div>
    )
})