import {Project, Skill, SkillsChapter} from "../../../../../model/types";
import React, {FunctionComponent} from "react";
import {SkillIcon} from "./SkillIcon";
import {SkillRating} from "./SkillRating";
import _ from "lodash";
import { getExperienceForSkill } from "./skillUtil";

export type SkillsFragmentProps = {
    skillsChapter: SkillsChapter
}

export const SkillsFragment: FunctionComponent<SkillsFragmentProps> = props => {
    const {skillsChapter: chapter} = props;
    const {text, skills, projects} = chapter;



    const mapSkill = (skill: Skill, index: number) => {
        return (
            <div key={index} className="flex skill-row">
                <SkillIcon skill={skill}/>
                <div className="flex align-items-center skill-name">
                    <h3>{skill.name}</h3>
                </div>
                <div className="flex align-items-center">
                    <p>{getExperienceForSkill(skill, projects)}</p>
                    {/* <SkillRating score={skill.rating}/> */}
                </div>
            </div>
        )
    }

    const mapSkills = (skills: Skill[], title: string) => {
        return (
            <div className="flex-column">
                <h1 className="padding-bottom-m">{title}</h1>
                {skills.map(mapSkill)}
            </div>
        )
    }

    const mappedSkills = _.groupBy(skills, 'skillType');

    return (
        <>
        <p>{text}</p>
        <div key={"skills"} className="chapter-content skills w100">
            {mapSkills(mappedSkills["BACKEND"], "Backend:")}
            {mapSkills(mappedSkills["FRONTEND"], "Frontend:")}
            {mapSkills(mappedSkills["DEVOPS"], "DevOps:")}
            {mapSkills(mappedSkills["DATABASE"], "Database:")}
        </div>
        </>
    )
}