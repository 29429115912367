import {HomePage} from "../model/types";
import {projects} from "./projects";
import {textIntroduction} from "../texts/textIntroduction";
import {ALL_SKILLS} from "./Skills";
import {textWhyMe} from "../texts/textWhyMe";
import {textOffer, textOfferExtended} from "../texts/textOffer";
import {educationStages} from "./education";

export const OFFER_CHAPTER = "Angebot";
export const ABOUT_ME_CHAPTER = "Über mich";
export const SKILLS_CHAPTER = "Fähigkeiten";
export const BLOG_CHAPTER = "Blog";
export const CONTACT_CHAPTER = "Kontakt";
export const PROJECTS_CHAPTER = "Projekte"
export const EDUCATION_CHAPTER = "Ausbildung"
export const DOWNLOADS_CHAPTER = "Downloads"

const mail = "info@englberger-it.de";

export const mockData: HomePage = {
    name: "Florian",
    surname: "Englberger",
    title: "Fullstack Software Engineer",
    mail: mail,
    slogan: "Helping customers to build exactly the software they need",
    mainPicture: {
        id: 'mainPicture',
        url: 'test',
        text: 'Main Picture',
    },
    navigationElements: [
        {
            header: 'Looking for a freelancer?',
            destinations: [PROJECTS_CHAPTER, SKILLS_CHAPTER, CONTACT_CHAPTER],
        },
        {
            header: 'Looking for knowledge?',
            destinations: [BLOG_CHAPTER],
        },
    ],
    chapters: [
        {
            title: OFFER_CHAPTER,
            offers: textOfferExtended,
        },
        {
            title: ABOUT_ME_CHAPTER,
            texts: textIntroduction.map((paragraph, index) => {
                return {
                    textType: "p",
                    text: paragraph,
                    id: index + "introduction",
                }
            })
        },
        {
            type: "SKILLS",
            title: SKILLS_CHAPTER,
            text: "Hier findest du eine Übersicht meiner Fähigkeiten und wie viele Jahre ich diese schon einsetze.",
            skills: ALL_SKILLS,
            projects: projects,
        },
        {
            type: "PROJECTS",
            title: PROJECTS_CHAPTER,
            projects: projects,
        },
        {
            title: EDUCATION_CHAPTER,
            educationStages: educationStages,
        },
        {
            title: DOWNLOADS_CHAPTER,
            downloadsDescription: "Hier finden Sie informationen zum Download",
            projectsForDownload: projects,
            skillsForDownload: ALL_SKILLS,
            educationForDownload: educationStages,
            cvAboutMe: textIntroduction.slice(0,3),
            buttonLabels: [
                {
                    id: "PROJECT_OVERVIEW",
                    label: "Projektübersicht",
                },
                {
                    id: "CV",
                    label: "Lebenslauf",
                }
            ]
        },
        // {
        //     title: BLOG_CHAPTER,
        //     texts: [
        //         {
        //             textType: "p",
        //             text: textChapter1,
        //             id: "Home##p"
        //         },
        //     ],
        // },
        {
            title: CONTACT_CHAPTER,
            text:"Ich bin immer offen für interessante Projekte - treten Sie gerne mit mir in Kontakt",
            description: "",
            mail: mail,
            linkedIn: "https://www.linkedin.com/in/florian-englberger-it"
        }
    ],
}