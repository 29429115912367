import { Project, Skill } from "../../../../../model/types";

export const getExperienceForSkill = (skill: Skill, projects: Project[]) => {
    return projects
        .filter(project => project.skills.includes(skill))
        .map(project => getDurationBetweenTwoDatesInYears(project.start, project.end))
        .reduce((a, b) => a + b, 0)
}

const getDurationBetweenTwoDatesInYears = (start: Date, end: Date) => {
    return roundToOneDigit((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24 * 30 * 12));
}
const roundToOneDigit = (value: number) => {
    return Math.round(value * 2) / 2;
}