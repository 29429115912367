import {FunctionComponent} from "react";
import "./topMenu.css";
import {MenuButton} from "../../mainContent/fragments/atoms/buttons/menuButton/MenuButton";
import {HomeButton} from "../../mainContent/fragments/atoms/buttons/homeButton/HomeButton";

export interface MenuButtonProps {
}

export const TopMenu: FunctionComponent<MenuButtonProps> = () => {

    return (
        <div id={"top_menu"} className={ "flex content-space-between"}>
            <HomeButton/>
            <MenuButton/>
        </div>
    )
}