import React, {FunctionComponent} from "react";
import {Link} from "react-scroll";

export interface HomeButtonProps {
}

export const HomeButton: FunctionComponent<HomeButtonProps> = () => {
    return (
        <Link
            className={"logo-container font-pacifico"}
            to={"top"}
            smooth={true}
            duration={500}
            containerId={"mainContent"}
        >
            <img className={"logo"} src={"android-chrome-192x192.png"} alt={"FE"}/>
        </Link>
    )
}