import React, {FunctionComponent} from "react";
import {Mailto} from "../../mailto/Mailto";
import "./header.css";

export interface HeaderFragmentProps {
    mail: string,
    slogan: string
    name: string,
    surname: string,
    title: string,
}

export const HeaderFragment: FunctionComponent<HeaderFragmentProps> = props => {
    const {mail, slogan, name, surname, title} = props;
    return (
        <div id={"top"} className={"flex width-fluid top"}>
            <img className={"main-picture margin--right-l margin-bottom-m"}
                 src={'./main_picture.jpg'} alt={""} />
            <div className={"flex-column padding-left-l"}>
                <div className={"mainTitle"}><b>{name}</b></div>
                <div className={"mainTitle"}><b>{surname}</b></div>
                <div className={"title"}><b>{title}</b></div>
                <Mailto className={"mail padding-top-l"} email={mail}>{mail}</Mailto>
                <p className="telefonNumber padding-top-l">+49 172 245 45 15</p>
                <br/>
                <p className={"slogan part1 padding-m padding-top-l"}>{`<-- ${slogan.substr(0, 26)}`}</p>
                <p className={"slogan part2 padding-m padding-top-l margin-left-l"}>{`${slogan.substr(26)} /-->`}</p>
            </div>
        </div>
    )
}