export const textOffer = [
    "Agile Softwareentwicklung in Front- und Backend mit Schwerpunkt Java und React",
    "Technische Projektleitung in agilen Software-Projekten",
    "Planung und Umsetzung skalierender Softwarearchitekturen",
    "Unterstützung und Weiterentwicklung anderer Entwickler durch Workshops, Pair-Programming und Merge Requests",
    "Vorbereitung und Durchführung von Workshops und Meetings",
    "Ausgeprägtes naturwissenschaftliches Verständnis",
]

export const textOfferExtended = [
    {
        header: "Entwicklung",
        text:"Code dient nicht nur zur Kommunikation mit dem Computer, sondern noch viel wichtiger zur Kommunikation mit anderen Entwicklern (und sich selbst nach kurzer Zeit)." +
            " Aus diesem Grund ist die Verständlichkeit und Testbarkeit eines meiner höchsten Ziele und darf nur aus sehr guten Gründen verringert werden."
    },
    {
        header: "Architektur",
        text:"Komplexität ist eine der größten Gefahren für Softwareprojekte. " +
            "Ich helfe Ihnen, dass ihr Projekt so komplex wie nötig, aber so simpel wie möglich wird und das vom Button bis zur Datenbank."
    },
    {
        header: "Technische Leitung",
        text:"Für ein gelungenes Projekt braucht es ein gut zusammenarbeitendes Team. " +
            "Gerne übernehme ich hier Verantwortung, damit nicht nur die Kommunikation mit dem Computer sondern auch zwischen allen Beteiligten stimmt."
    },
    {
        header: "Lernen und Lehren",
        text:"Um effizient zu lernen braucht man einen Lehrer. " +
            "Ich freue mich über jeden der mir hilft mich weiterzuentwickeln " +
            "und bin gerne bereit jedem in Form von Workshops, Pair-Programming, Pull-Requests, oder auch einer netten Diskussion, weiterzuhelfen."
    },
]