import React, {FunctionComponent} from 'react';
import {Menu} from "../menu/Menu";
import "./mainPage.css"
import {MainContent} from "../mainContent/MainContent";
import {mockData} from "../data/mockData";

type MainPageProps = {
}

export const MainPage: FunctionComponent<MainPageProps> = () => {

    const data = mockData;

    return (
        <div className='MainPage flex'>
            <Menu data={data}/>
            <MainContent data={data}/>
        </div>
    )
}
