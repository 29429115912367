import {Skill} from "../model/types";


export const JAVA: Skill = {
    name: "Java",
    logoUrl: 'logo-java-min.png',
    children: [],
    skillType: "BACKEND",
    rating:4,
}

export const JEE: Skill = {
    name: "JEE",
    logoUrl: "logo-javaee-min.png",
    children: [],
    skillType: "BACKEND",
    rating:3,
}

export const HYBRIS: Skill = {
    name: "Hybris",
    logoUrl: "logo-hybris-min.png",
    children: [],
    skillType: "BACKEND",
    rating:4,
}


export const SPRING_CORE: Skill = {
    name: "Spring Core",
    children: [],
    skillType: "BACKEND",
    rating:4,
}
export const SPRING_BOOT: Skill = {
    name: "Spring Boot",
    children: [],
    skillType: "BACKEND",
    rating:4,
}

export const SPRING_DATA_JPA: Skill = {
    name: "Spring Data JPA",
    children: [],
    skillType: "BACKEND",
    rating:4,
}
export const SPRING_DATA_MONGODB: Skill = {
    name: "Spring Data MongoDB",
    children: [],
    skillType: "BACKEND",
    rating:3,
}
export const SPRING_BATCH: Skill = {
    name: "Spring Batch",
    children: [],
    skillType: "BACKEND",
    rating:3,
}
export const SPRING_MVC: Skill = {
    name: "Spring MVC",
    children: [],
    skillType: "BACKEND",
    rating:4,
}

export const QUARKUS: Skill = {
    name: "Quarkus",
    logoUrl: "logo-quarkus-min.png",
    children: [],
    skillType: "BACKEND",
    rating:3,
}

export const SPRING_DATA: Skill = {
    name: "Spring Data",
    children: [SPRING_DATA_JPA, SPRING_DATA_MONGODB],
    skillType: "BACKEND",
    rating:3,
}

export const SPRING: Skill = {
    name: "Spring",
    logoUrl: "logo-spring-min.png",
    children: [SPRING_CORE, SPRING_BOOT, SPRING_DATA, SPRING_BATCH, SPRING_MVC],
    skillType: "BACKEND",
    rating:5,
}

export const REACT: Skill = {
    name: "React",
    logoUrl: "logo-react-min.png",
    children: [],
    skillType: "FRONTEND",
    rating:5,
}
export const VUE: Skill = {
    name: "Vue",
    logoUrl: "logo-vue-min.png",
    children: [],
    skillType: "FRONTEND",
    rating:4,
}

export const JAVA_SCRIPT: Skill = {
    name: "JavaScript",
    logoUrl: 'logo-js-min.png',
    children: [REACT, VUE],
    skillType: "FRONTEND",
    rating:4,
}
export const TYPE_SCRIPT: Skill = {
    name: "TypeScript",
    logoUrl: 'logo_typescript-min.png',
    children: [],
    skillType: "FRONTEND",
    rating:4,
}
export const JAVA_SWING: Skill = {
    name: "Java Swing",
    children: [],
    skillType: "FRONTEND",
    rating:2,
}
export const JAVA_FX: Skill = {
    name: "JavaFx",
    children: [REACT, VUE],
    skillType: "FRONTEND",
    rating:2,
}
export const J_QUERY: Skill = {
    name: "JQuery",
    children: [REACT, VUE],
    skillType: "FRONTEND",
    rating:2,
}

export const POSTGRES: Skill = {
    name: "Postgres",
    logoUrl: "logo-postgres-min.png",
    children: [],
    skillType: "DATABASE",
    rating:4,
}

export const ORACLE: Skill = {
    name: "Oracle DB",
    logoUrl: "logo-oracleDB-min.png",
    children: [],
    skillType: "DATABASE",
    rating:3,
}
export const MONGO_DB: Skill = {
    name: "MongoDB",
    logoUrl: "logo-mongoDB-min.png",
    children: [],
    skillType: "DATABASE",
    rating:3,
}
export const MAVEN: Skill = {
    name: "Maven",
    logoUrl: "logo-maven-min.png",
    children: [],
    skillType: "DEVOPS",
    rating:4,
}
export const GRADLE: Skill = {
    name: "Gradle",
    logoUrl: "logo-gradle-min.png",
    children: [],
    skillType: "DEVOPS",
    rating:2,
}
export const DOCKER: Skill = {
    name: "Docker",
    logoUrl: "logo-docker-min.png",
    children: [],
    skillType: "DEVOPS",
    rating:3,
}
export const KUBERNETES: Skill = {
    name: "Kubernetes",
    logoUrl: "logo-kubernetes-min.png",
    children: [],
    skillType: "DEVOPS",
    rating:2,
}
export const CSS: Skill = {
    name: "CSS",
    logoUrl: "logo-css-min.png",
    children: [],
    skillType: "FRONTEND",
    rating:4,
}



export const ALL_SKILLS: Skill[] =
    [
        //Backend
        JAVA,
        SPRING,
        QUARKUS,
        HYBRIS,
        JEE,
        //Frontend
        JAVA_SCRIPT,
        TYPE_SCRIPT,
        REACT,
        VUE,
        CSS,
        // Database
        POSTGRES,
        ORACLE,
        MONGO_DB,
        //DevOps
        MAVEN,
        GRADLE,
        DOCKER,
        KUBERNETES,
    ];

