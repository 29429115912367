import React, {Dispatch, SetStateAction, useState} from "react";
import {Theme} from "../model/types";

export const HomePageContext = React.createContext<HomePageContextProps>({
    theme: "dark",
    toggleTheme: () => {
        return;
    },
    isMenuOpen: false,
    setMenuVisibility: _ => {return;}
})

export type HomePageContextProps = {
    theme: Theme,
    toggleTheme: () => void;
    isMenuOpen: boolean;
    setMenuVisibility: Dispatch<SetStateAction<boolean>>;
}

export const useHomePageContext: () => HomePageContextProps = () => {
    const [theme, setTheme] = useState<Theme>("dark")
    const [isMenuOpen, setMenuVisibility] = useState<boolean>(false)


    const toggleTheme = () => {
        setTheme(prevState => prevState === "dark" ? "light" : "dark");
    }
    return {
        theme,
        toggleTheme,
        isMenuOpen,
        setMenuVisibility,
    }
}