import {FunctionComponent, useContext} from "react";
import {Squeeze as Hamburger} from "hamburger-react";
import {HomePageContext} from "../../../../../context/HomePageContext";
import "./menuButton.css";

export interface MenuButtonProps {
}

export const MenuButton: FunctionComponent<MenuButtonProps> = () => {

    const {isMenuOpen, setMenuVisibility} = useContext(HomePageContext);
    return (
        <Hamburger toggled={isMenuOpen} toggle={setMenuVisibility} duration={0.8}/>
    )
}