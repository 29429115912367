import {EducationStage} from "../model/types";

export const educationStages: EducationStage[] = [
    {
        start: new Date(2014,10),
        end: new Date(2016,10),
        degree: "Master of Science",
        institution: "Technische Universität München",
        name: "Bauingenieurwesen",
        grade: 1.5,
    },
    {
        start: new Date(2010,10),
        end: new Date(2014,10),
        degree: "Bachelor of Science",
        institution: "Technische Universität München",
        name: "Bauingenieurwesen",
        grade: 2.7,
    },
]