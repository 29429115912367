import {FunctionComponent} from "react";
import {OfferChapter} from "../../../../../model/types";
import "./offerChapter.css";

export interface OfferFragmentProps {
    offerChapter: OfferChapter
}

export const OfferFragment: FunctionComponent<OfferFragmentProps> = ({offerChapter}) => {
    return (
        <div className={"offer_chapter chapter-content"}>
            {offerChapter.offers.map((offer, index) => {
                return (
                    <div key={index}>
                        <h3>{offer.header}</h3>
                        <p>{offer.text}</p>
                    </div>
                )
            })}
                </div>
                )
            }