import React, {FunctionComponent} from "react";
import {HomePage} from "../model/types";
import "./mainContent.css";
import {ChapterFragment} from "./fragments/content/chapter/ChapterFragment";
import {HeaderFragment} from "./fragments/content/header/HeaderFragment";
import {Impressum} from "./fragments/content/impressum/impressum";

export type MainContentProps = {
    data: HomePage;
}

export const MainContent: FunctionComponent<MainContentProps> = ({data}) => {

    return (
        <div id={"mainContent"} style={{backgroundImage: "url(./background.webp)"}}
             className={"mainContent flex-column align-items-center"}>
            <div className="overlay">
                <div className="spacer">
                    <HeaderFragment
                        mail={data.mail}
                        slogan={data.slogan}
                        name={data.name}
                        surname={data.surname}
                        title={data.title}
                    />
                    {/*<NavigationBlock navigationElements={data.navigationElements}/>`*/}
                    {data.chapters.map(chapter => <ChapterFragment chapter={chapter}/>)}
                    <Impressum/>
                </div>
            </div>
        </div>
    );
}