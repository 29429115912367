import {FunctionComponent} from "react";
import "./impressum.css";

export interface ImpressumProps {
}

export const Impressum: FunctionComponent<ImpressumProps> = () => {
    return (
        <div className="impressum margin-bottom-l chapter">
            <h1 className="title" id="Impressum">Impressum</h1>
            <div className={"impressum-body"}>
                <div className="impressum-text">
                    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                    <p>Florian Englberger<br/>
                        Softwareingenieur<br/>
                        Herbststra&szlig;e<br/>
                        18<br/>
                        86830 Schwabm&uuml;nchen</p>

                    <h2>Kontakt</h2>
                    <p>Telefon: 01722454515<br/>
                        E-Mail: info@englberger-it.de</p>

                    <h2>Umsatzsteuer-ID</h2>
                    <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br/>
                        DE346110443</p>
                </div>
                <div className="impressum-text">
                    <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
                    <p><strong>Name und Sitz des Versicherers:</strong><br/>
                        Hiscox SA<br/>
                        Arnulfstra&szlig;e 31<br/>
                        80636 M&uuml;nchen</p>
                    <p><strong>Geltungsraum der Versicherung:</strong><br/>Deutschland</p>

                    <h2>Redaktionell verantwortlich</h2>
                    <p>Florian Englberger<br/>
                        Herbststra&szlig;e 18<br/>
                        86830 Schwabm&uuml;nchen</p>

                    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle teilzunehmen.</p>

                    <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
                </div>
            </div>
        </div>
    )
}