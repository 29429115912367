import React, {FunctionComponent} from 'react';
import {Page, Text, View, Document, Image, StyleSheet} from '@react-pdf/renderer';
import {EducationStage, Project, Skill, SkillType} from "../../../../../../model/types";
import {ABOUT_ME_CHAPTER, SKILLS_CHAPTER} from "../../../../../../data/mockData";
import {DateUtil} from "../../../../../../util/DateUtil";
import {educationStages} from "../../../../../../data/education";
import _, { get } from "lodash";
import { getExperienceForSkill } from '../../skill/skillUtil';

// Create styles
const PRIMARY_COLOR = '#2a2c2d';
const PRIMARY_TEXT_COLOR = '#F7F7F7';
const SECONDARY_COLOR = '#c75a28';

const NUMBER_OF_PROJECTS = 4;

const WIDTH_COL_1 = '30vw';
const WIDTH_COL_2 = '61vw';

const HEIGHT_HEADER = '12vh';
const HEIGHT_COL_1_LEFT = '50vh';
const HEIGHT_COL_1 = '30vh';
const HEIGHT_COL_2 = '20vh';

const BORDER_WIDTH = 2;

const SPACING_XSMALL = 3;
const SPACING_SMALL = 10;
const SPACING_MEDIUM = 15;

const FONT_SIZE_H1 = 20;
const FONT_SIZE_H2 = 15;
const FONT_SIZE_H3 = 12;
const FONT_SIZE_P = 9;

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        fontSize: FONT_SIZE_P,
    },
    leftPart: {
        width: WIDTH_COL_1,
        height: '100vh',
        backgroundColor: PRIMARY_COLOR,
        color: PRIMARY_TEXT_COLOR,
        padding: SPACING_MEDIUM,
    },
    imageView: {
        height: HEIGHT_HEADER,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: '50%',
        borderRadius: 30,
    },
    aboutMeView: {
        flexDirection: 'column',
        // height: HEIGHT_COL_1_LEFT,
        borderBottom: BORDER_WIDTH,
        borderColor: PRIMARY_TEXT_COLOR,
        paddingBottom: SPACING_MEDIUM,
    },
    H2: {
        fontSize: FONT_SIZE_H2,
        marginTop: SPACING_MEDIUM,
        marginBottom: SPACING_MEDIUM,
    },
    H3: {
        fontSize: FONT_SIZE_H3,
        color: SECONDARY_COLOR,
    },
    P: {
        fontSize: FONT_SIZE_P,
    },


    rightPart: {
        width: WIDTH_COL_2,
        height: '100vh',
        padding: SPACING_MEDIUM,
        paddingRight: SPACING_MEDIUM,
        color: PRIMARY_COLOR,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: HEIGHT_HEADER,
        borderBottom: BORDER_WIDTH,
        borderColor: PRIMARY_COLOR,
    },
    nameView: {
        flexDirection: 'column',
        justifyContent: 'center',
    },
    name: {
        textAlign: 'left',
        fontSize: FONT_SIZE_H1,
    },
    contactData: {
        textAlign: 'right',
        fontSize: FONT_SIZE_P,
        width: '20vw',
    },

    projectsView: {
        flexDirection: 'column',
        borderBottom: BORDER_WIDTH,
        borderColor: PRIMARY_COLOR,
    },
    educationView: {
        flexDirection: 'column',
        borderBottom: BORDER_WIDTH,
        borderColor: PRIMARY_COLOR,
    },

    skillsView: {
        flexDirection: 'column',
        height: HEIGHT_COL_2,
    },

    projectCard: {
        paddingBottom: SPACING_MEDIUM,
        flexDirection: 'row',
        width: "100%",
    },
    projectLeft: {
        width: '30%',
        flexDirection: 'column',
        borderRight: 1,
        borderColor: PRIMARY_COLOR,
        marginRight: SPACING_MEDIUM,
    },
    projectRight: {
        width: "80%",
        flexDirection: 'column',
    },


    skill: {
        flexDirection: "row",
        gap: 20,
        paddingTop: SPACING_XSMALL,
        paddingBottom: SPACING_XSMALL,
        paddingLeft:SPACING_XSMALL,
    },
    skillHeader: {
        marginBottom: SPACING_XSMALL,
        marginTop: SPACING_SMALL,
        fontSize: FONT_SIZE_H3,
        color: SECONDARY_COLOR
    },
    skillRating: {
        flexDirection: "row",
    },
    skillRow: {
        flexDirection: "row",
        width: '100%',
        marginTop: 3,
        marginBottom: 3,
    },
    skillTitle: {
        width: 70,
    },
    skillText:{
        fontSize: FONT_SIZE_P,
    },
    skillExperience: {
        width: 50,
        fontSize: FONT_SIZE_P,
    },
    skillCol: {
        flexDirection: 'column',
        width: '50%',
    },

});

export interface CvPdfProps {
    projects: Project[];
    education: EducationStage[];
    skills: Skill[];
    text: string[];
    imageSrc: string;
}

// Create Document Component
export const CvPdf: FunctionComponent<CvPdfProps> = ({imageSrc, text, skills, projects}) => {

    const mappedSkills = _.groupBy(skills, 'skillType');

    const createSkillRow = (header: string, key: SkillType) => {
        return (
            <View style={styles.skillCol}>
                <Text style={styles.skillHeader}>{header}</Text>
                {mappedSkills[key].map(skill => {
                    return (
                        <View style={styles.skill}>
                            <Text style={styles.skillTitle}>{skill.name}</Text>
                            <Text style={styles.skillExperience}>{getExperienceForSkill(skill, projects)}</Text>
                        </View>
                    )
                })}
            </View>
        )
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.leftPart}>
                    <View style={styles.imageView}>
                        <Image style={styles.image} src={imageSrc}/>
                    </View>
                    <View style={styles.aboutMeView}>
                        <Text style={styles.H2}>{ABOUT_ME_CHAPTER.toUpperCase()}</Text>
                        {text.map(paragraph => <Text>{paragraph}</Text>)}
                    </View>
                    <View style={styles.aboutMeView}>
                        <Text style={styles.H2}>SPRACHEN</Text>
                        <Text>Deutsch</Text>
                        <Text>Englisch</Text>
                        <Text>Französisch</Text>
                    </View>
                    <View style={styles.aboutMeView}>
                        <Text style={styles.H2}>HOBBIES</Text>
                        <Text>Handball</Text>
                        <Text>Schach</Text>
                        <Text>Gitarre</Text>
                    </View>
                </View>
                <View style={styles.rightPart}>
                    <View style={styles.header}>
                        <View style={styles.nameView}>
                            <Text style={styles.name}>FLORIAN</Text>
                            <Text style={styles.name}>ENGLBERGER</Text>
                            <Text style={styles.H3}>SOFTWARE-INGENIEUR</Text>
                        </View>
                        <View style={styles.nameView}>
                            <Text style={styles.contactData}>info@englberger-it.de</Text>
                            <Text style={styles.contactData}>+49 172 245 45 15</Text>
                            <Text style={styles.contactData}>Herbststraße 18</Text>
                            <Text style={styles.contactData}>86830 Schwabmünchen</Text>
                            <Text style={styles.contactData}>www.englberger-it.de</Text>
                        </View>
                    </View>
                    <View style={styles.projectsView}>
                        <Text style={styles.H2}>LETZTE PROJEKTE</Text>
                        {projects.slice(0, NUMBER_OF_PROJECTS).map(project => {
                            return (
                                <View style={styles.projectCard}>
                                    <View style={styles.projectLeft}>
                                        <Text>{DateUtil.formatDate(project.start)} - {DateUtil.formatDate(project.end)}</Text>
                                    </View>
                                    <View style={styles.projectRight}>
                                        <Text style={styles.H3}>{project.title}</Text>
                                        {project.text.map(paragraph => <Text>{paragraph}</Text>)}
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                    <View style={styles.educationView}>
                        <Text style={styles.H2}>AUSBILDUNG</Text>
                        {educationStages.map(stage => {
                            return (
                                <View style={styles.projectCard}>
                                    <View style={styles.projectLeft}>
                                        <Text>{DateUtil.formatDate(stage.start)} - {DateUtil.formatDate(stage.end)}</Text>
                                    </View>
                                    <View style={styles.projectRight}>
                                        <Text style={styles.H3}>{stage.degree}</Text>
                                        <Text>{stage.name}</Text>
                                        <Text>{stage.institution}</Text>
                                        <Text>{stage.grade}</Text>
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                    <View style={styles.skillsView}>
                        <Text style={styles.H2}>{SKILLS_CHAPTER.toUpperCase()}</Text>
                        <Text style={styles.skillText }>Übersicht meiner Fähigkeiten und wie viele Jahre ich diese schon einsetze.</Text>
                        <View style={styles.skillRow}>
                            {createSkillRow("Backend", "BACKEND")}
                            {createSkillRow("Frontend", "FRONTEND")}
                        </View>
                        <View style={styles.skillRow}>
                            {createSkillRow("DevOps", "DEVOPS")}
                            {createSkillRow("Datenbanken", "DATABASE")}
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
};