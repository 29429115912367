import React, {FunctionComponent} from "react";
import {EducationChapter, EducationStage, Project} from "../../../../../model/types";
import {DateUtil} from "../../../../../util/DateUtil";
import "./../project/ProjectFragment.css";

export interface EducationFragmentProps {
    educationChapter: EducationChapter;
}

export const EducationFragment: FunctionComponent<EducationFragmentProps> = ({educationChapter}) => {

    const renderRow = (title: string, text: string[]) => {
        return (
            <div className="flex padding-s project-row">
                <h2 className="project-subtitle  padding-bottom-s">{title}:</h2>
                <div className="flex-column">
                    {text.map((it, index) => {
                        return (<div className={"project-text"} key={index}>{it}</div>)
                    })}
                </div>
            </div>
        )
    }

    function renderEducationStageCard(educationStage: EducationStage, index: number) {
        return (
            <div key={index} className="project-card chapter-content flex-column padding-s border-radius">
                {educationStage.start && renderRow("Start", [DateUtil.formatDate(educationStage.start)])}
                {renderRow("Ende", [DateUtil.formatDate(educationStage.end)])}
                {renderRow("Abschluss", [educationStage.degree])}
                {educationStage.name && renderRow("Name", [educationStage.name])}
                {renderRow("Institut", [educationStage.institution])}
                {educationStage.grade && renderRow("Note", [educationStage.grade.toString()])}
            </div>
        )
    }

    return (
        <div key={educationChapter.title} className="timeline">
            <div className="outer">
                {educationChapter.educationStages.map(renderEducationStageCard)}
            </div>
        </div>
    )
}