import React, {FunctionComponent} from "react";
import {Project, ProjectChapter, Skill} from "../../../../../model/types";
import _ from "lodash";
import "./ProjectFragment.css";
import {SkillIcon} from "../skill/SkillIcon";
import {DateUtil} from "../../../../../util/DateUtil";

export type ProjectFragmentProps = {
    projectChapter: ProjectChapter
}

export const ProjectFragment: FunctionComponent<ProjectFragmentProps> = (props) => {
    const {projectChapter} = props;

    const renderRow = (title: string, text: string[]) => {
        return (
            <div className="flex padding-s project-row">
                <h2 className="project-subtitle  padding-bottom-s">{title}:</h2>
                <div className="flex-column">
                    {text.map((it, index) => {
                        return (<div className={"project-text"} key={index}>{it}</div>)
                    })}
                </div>
            </div>
        )
    }

    const mapSkills = (skills: Skill[] | undefined, title: string) => {
        return skills && (
            <div className="flex-column padding-right-l margin-bottom-m">
                <h3 className="margin-bottom-s">{title}</h3>
                {skills.map((it, index) => {
                    return (<div className={"project-text"} key={index}>{it.name}</div>)
                })}
            </div>
        )
    }

    const renderSkillRow = (skills: Skill[]) => {
        const mappedSkills = _.groupBy(skills, 'skillType');
        return (
            <div className="flex padding-s project-row">
                <h2 className="project-subtitle padding-bottom-s">Fähigkeiten:</h2>
                <div className="flex skill-list">
                    {mapSkills(mappedSkills["FRONTEND"], "Frontend:")}
                    {mapSkills(mappedSkills["BACKEND"], "Backend:")}
                    {mapSkills(mappedSkills["DEVOPS"], "DevOps:")}
                    {mapSkills(mappedSkills["DATABASE"], "Database:")}
                </div>
            </div>
        );
    }

    function renderIcons(skills: Skill[]) {
        return <div className="project-icons">
            {skills
                .filter(skill => skill.logoUrl !== undefined)
                .map((skill, index) => <SkillIcon key={index} skill={skill}/>)
            }
        </div>;
    }

    function renderProjectCard(project: Project, index: number) {
        return (
            <div key={index} className="project-card chapter-content flex-column padding-s border-radius">
                {renderIcons(project.skills)}
                {renderRow("Start", [DateUtil.formatDate(project.start)])}
                {renderRow("Ende", [DateUtil.formatDate(project.end)])}
                {renderRow("Titel", [project.title])}
                {renderRow("Feld", [project.field])}
                {renderRow("Beschreibung", project.text)}
                {renderSkillRow(project.skills)}
                {renderRow("Verantwortlichkeiten", project.responsibilities)}
            </div>
        )
    }

    return (
            <div key={projectChapter.title} className="timeline">
                <div className="outer">
                    {projectChapter.projects.map(renderProjectCard)}
                </div>
            </div>
    )
}