import React, {FunctionComponent} from "react";
import './Menu.css'
import {determineIfChapterIsTextChapter, HomePage, MenuItem} from "../model/types";
import {SideMenu} from "./sideMenu/SideMenu";
import {TopMenu} from "./topMenu/TopMenu";

type MenuProps = {
    data: HomePage
}

export const Menu: FunctionComponent<MenuProps> = ({data}) => {

    const menuData: MenuItem[] = data.chapters.map(chapter => {

        const subchapters = determineIfChapterIsTextChapter(chapter) ? chapter.subchapters || [] : [];

        return {
            title: chapter.title,
            subchapters: subchapters.map(subchapter => subchapter.title)
        }
    })

    menuData.push({title: "Impressum", subchapters:[]});

    return (
        <>
            <SideMenu menuItems={menuData}/>
            <TopMenu/>
        </>
    )
}
