import React, {FunctionComponent} from "react";

export type MailtoProps = {
    email: string,
    subject?: string,
    body?: string,
    className?: string,
    children? :string,
}

export const Mailto: FunctionComponent<MailtoProps> = ({ email, subject, body, className, ...props }) => {
    return (
        <a className={className} href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
            {props.children}
        </a>
    );
}
